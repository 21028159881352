//noinspection CssUnknownTarget
@import '~viewerjs/dist/viewer.min.css';
//noinspection CssUnknownTarget
@import '~katex/dist/katex.min.css';
@import 'branding';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Platypi:ital,wght@0,300..800;1,300..800&display=swap');

:root {
	--bs-font-sans-serif: 'Noto Sans', system-ui;
	--bs-font-monospace: 'Noto Sans Mono', monospace;
	--bs-body-font-family: var(--bs-font-sans-serif);
	--bs-body-font-size: 1rem;
	--bs-body-font-weight: 400;
	--bs-body-line-height: 1.9;

	--g8-header-font-family: 'Platypi', system-ui;
	--g8-header-line-height: 1.4;

	--focus-box-shadow: 0 0 0 1px white, 0 0 0 4px var(--g8-primary-opacity-50);
	--active-box-shadow: 0 0 0 3px var(--g8-primary-opacity-25);
}

// * {
// 	line-height: var(--bs-body-line-height);
//   font-family: var(--bs-font-sans-serif);
//   font-optical-sizing: auto;
//   font-weight: var(--bs-body-font-weight);
//   font-style: normal;
//   font-variation-settings:
//     "wdth" 100;
// }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	//noinspection CssNoGenericFontName
	font-family: var(--g8-header-font-family);
	font-optical-sizing: auto;
	font-style: normal;
	line-height: var(--g8-header-line-height);
}

.header {
	//noinspection CssNoGenericFontName
	font-family: var(--g8-header-font-family);
}

.badge {
	//noinspection CssNoGenericFontName
	font-family: var(--bs-font-sans-serif);
}

// ------------ FADING EFFECT WHEN A VALUE WAS UPDATED ------------
@keyframes updated-fader {
	0% {
		background-color: var(--bs-primary);
	}
	100% {
		background-color: transparent;
	}
}

.value-updated {
	position: relative;
}

.value-updated::after {
	animation-name: updated-fader;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-timing-function: ease-out;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	pointer-events: none;
	opacity: 0.2;
}

// ------------ ACCORDION FIX ------------
.accordion-item:first-of-type .accordion-header .accordion-button {
	border-top-left-radius: var(--bs-accordion-inner-border-radius);
	border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-header .accordion-button.collapsed {
	border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
	border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-button:hover {
	background-color: var(--g8-primary-opacity-10);
}

.accordion-button:focus-within {
	box-shadow: var(--focus-box-shadow);
}

.accordion-button:not(.collapsed) {
	background-color: var(--g8-secondary-opacity-10);
	color: var(--g8-secondary-darker-50);
}

// also overwrite fonts inside the headers
// h1 *:not([class^="fa-"]), .h1 *:not([class^="fa-"]), h2 *:not([class^="fa-"]), .h2 *:not([class^="fa-"]), h3 *:not([class^="fa-"]), .h3 *:not([class^="fa-"]), h4 *:not([class^="fa-"]), .h4 *:not([class^="fa-"]), h5 *:not([class^="fa-"]), .h5 *:not([class^="fa-"]), h6 *:not([class^="fa-"]), .h6 *:not([class^="fa-"]) {
// 	font-family: inherit;
// }

.fs-7 {
	font-size: 0.875rem;
}

.fs-8 {
	font-size: 0.75rem;
}

::-moz-selection {
	/* Code for Firefox */
	color: white;
	background: var(--bs-primary);
}

::selection {
	color: white;
	background: var(--bs-primary);
}

.text-muted {
	opacity: 0.7;
}

.katex-display {
	margin-left: 1.5rem;
	overflow-x: auto;
	overflow-y: hidden;
}

.nav-tabs > .nav-item > a {
	border-color: #dee2e6;
}

.nav-link {
	color: var(--g8-primary-darker-35);
	transition: // Webstorm formatting is different than prettier here, but this comment fixes it, so please dont remove me :)
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}

.nav-link:focus-visible,
.nav-link:hover {
	color: var(--g8-primary-darker-20);
}

.nav-link:focus-visible {
	box-shadow: var(--focus-box-shadow);
}

label {
	display: flex;
	font-weight: bold;
	color: #333;
}

.container-header {
	position: relative;
	z-index: 1;
}

.container-header > hr.container-header-line {
	margin: 0;
}

.container-header > .container-header-content {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	padding: 0 1rem;

	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.btn:not(:disabled):focus-visible,
button:not(:disabled):focus-visible {
	box-shadow: var(--focus-box-shadow);
}

.btn {
	font-weight: 500;
}

.btn.border-med:hover,
.btn-group.border-med:hover {
	background-color: #eff2f4 !important;
}

.border-med,
.border-med:hover {
	border: 1px solid #ced4da;
}

.btn:not(.btn-group),
.btn-group,
.g8shadow {
	box-shadow: 0.125rem 0.125rem rgba($color: #000000, $alpha: 0.075);
}

.btn.active,
.btn-group.active,
.g8shadow.active,
input:not([type='checkbox']):not([type='range']):not([type='radio']),
textarea,
select,
.g8shadow-in {
	box-shadow: inset 0.125rem 0.125rem rgba($color: #000000, $alpha: 0.075);
}

.btn[disabled],
.btn-group[disabled],
.g8shadow[disabled],
input[disabled]:not([type='checkbox']):not([type='range']):not([type='radio']),
textarea[disabled],
select[disabled],
.g8shadow-in[disabled] {
	opacity: 0.85;
	background-color: #e9ecef;
	color: var(--bs-dark);
}

.btn[disabled],
.btn-group[disabled] {
	border: 0;
}

.btn.active {
	box-shadow: inset 0.25rem 0.25rem rgba($color: #000000, $alpha: 0.2);
	mask: radial-gradient(ellipse, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1));
	mask-clip: no-clip;
}

p code {
	background: var(--bs-light);
	display: inline-block;
	padding: 0 0.3rem;
	border-radius: 0.4rem;
}

.list-group-item {
	background-color: transparent;
}

// .list-group-item * {
// 	line-height: 1.6rem;
// }
.list-group-item.active {
	color: #fff;
	background-color: var(--bs-secondary) !important;
	border-color: var(--bs-secondary) !important;
}

/* Scrollbar styles */
::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

::-webkit-scrollbar-track {
	background: #00000015;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: #00000059;
}

::-webkit-scrollbar-thumb:hover {
	background: #0000007c;
}

.tooltip {
	background-color: var(--bs-tooltip-bg);
	color: var(--bs-tooltip-color);
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 1rem;
	white-space: nowrap;
	z-index: 10000;
	position: absolute;
	opacity: 1;
	pointer-events: none;
	max-width: 100vw !important;
	display: none;
}

// When running the project in development mode, a bar appears at the bottom of the page, potentially blocking some
//  content, which is very annoying. This class hides it.
.firebase-emulator-warning {
	display: none;
}

.w-fit {
	width: fit-content;
}

.fullscreen-overlay {
	position: fixed;
	inset: 1rem;
	z-index: 99999;
	box-shadow: var(--bs-box-shadow);
	background: white;
	max-height: calc(100vh - 2rem) !important;
	overflow-y: auto;
	border-radius: 0.5rem;

	&::before {
		content: '';
		position: fixed;
		inset: 0;
		z-index: -1;
		background: rgba(0, 0, 0, 0.25);
	}
}

// Bootstrap adds '!important' after the 'h-100' class. This is bad because it will lead to strange overflowing effects
//  in certain scenarios. This is also very annoying to debug and fix, so it's better to remove the '!important'.
.h-100-but-not-important {
	height: 100%;
}

// Prevent scrolling of the body when the fullscreen overlay is open
body:has(.fullscreen-overlay) {
	overflow: hidden;
}

.unset {
	all: unset;
}

// Will get added to every element that uses the innerChatMessage directive. Used to make sure images don't overflow.
.chat-message-content {
	img {
		max-width: 100%;
		max-height: 50vh;
		border-radius: 0.25rem;
	}

	p:last-child {
		margin-bottom: 0 !important;
	}
}
