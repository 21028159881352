$g8-primary: rgb(120, 58, 235);
$g8-primary-darker-05: mix($g8-primary, black, 95%);
$g8-primary-darker-10: mix($g8-primary, black, 90%);
$g8-primary-darker-15: mix($g8-primary, black, 85%);
$g8-primary-darker-20: mix($g8-primary, black, 80%);
$g8-primary-darker-25: mix($g8-primary, black, 75%);
$g8-primary-darker-30: mix($g8-primary, black, 70%);
$g8-primary-darker-35: mix($g8-primary, black, 65%);
$g8-primary-darker-40: mix($g8-primary, black, 60%);
$g8-primary-darker-45: mix($g8-primary, black, 55%);
$g8-primary-darker-50: mix($g8-primary, black, 50%);

$g8-primary-opacity-10: rgba($g8-primary, 0.1);
$g8-primary-opacity-25: rgba($g8-primary, 0.25);
$g8-primary-opacity-50: rgba($g8-primary, 0.5);
$g8-primary-opacity-75: rgba($g8-primary, 0.75);
$g8-primary-opacity-90: rgba($g8-primary, 0.9);

$g8-secondary: #6c757d;
$g8-secondary-darker-05: mix($g8-secondary, black, 95%);
$g8-secondary-darker-10: mix($g8-secondary, black, 90%);
$g8-secondary-darker-15: mix($g8-secondary, black, 85%);
$g8-secondary-darker-20: mix($g8-secondary, black, 80%);
$g8-secondary-darker-25: mix($g8-secondary, black, 75%);
$g8-secondary-darker-30: mix($g8-secondary, black, 70%);
$g8-secondary-darker-35: mix($g8-secondary, black, 65%);
$g8-secondary-darker-40: mix($g8-secondary, black, 60%);
$g8-secondary-darker-45: mix($g8-secondary, black, 55%);
$g8-secondary-darker-50: mix($g8-secondary, black, 50%);

$g8-secondary-opacity-10: rgba($g8-secondary, 0.1);
$g8-secondary-opacity-25: rgba($g8-secondary, 0.25);
$g8-secondary-opacity-50: rgba($g8-secondary, 0.5);
$g8-secondary-opacity-75: rgba($g8-secondary, 0.75);
$g8-secondary-opacity-90: rgba($g8-secondary, 0.9);

:root {
	--g8-primary: #{$g8-primary};
	--g8-primary-darker-05: #{$g8-primary-darker-05};
	--g8-primary-darker-10: #{$g8-primary-darker-10};
	--g8-primary-darker-15: #{$g8-primary-darker-15};
	--g8-primary-darker-20: #{$g8-primary-darker-20};
	--g8-primary-darker-25: #{$g8-primary-darker-25};
	--g8-primary-darker-30: #{$g8-primary-darker-30};
	--g8-primary-darker-35: #{$g8-primary-darker-35};
	--g8-primary-darker-40: #{$g8-primary-darker-40};
	--g8-primary-darker-45: #{$g8-primary-darker-45};
	--g8-primary-darker-50: #{$g8-primary-darker-50};

	--g8-primary-opacity-10: #{$g8-primary-opacity-10};
	--g8-primary-opacity-25: #{$g8-primary-opacity-25};
	--g8-primary-opacity-50: #{$g8-primary-opacity-50};
	--g8-primary-opacity-75: #{$g8-primary-opacity-75};
	--g8-primary-opacity-90: #{$g8-primary-opacity-90};

	--g8-secondary: #{$g8-secondary};
	--g8-secondary-darker-05: #{$g8-secondary-darker-05};
	--g8-secondary-darker-10: #{$g8-secondary-darker-10};
	--g8-secondary-darker-15: #{$g8-secondary-darker-15};
	--g8-secondary-darker-20: #{$g8-secondary-darker-20};
	--g8-secondary-darker-25: #{$g8-secondary-darker-25};
	--g8-secondary-darker-30: #{$g8-secondary-darker-30};
	--g8-secondary-darker-35: #{$g8-secondary-darker-35};
	--g8-secondary-darker-40: #{$g8-secondary-darker-40};
	--g8-secondary-darker-45: #{$g8-secondary-darker-45};
	--g8-secondary-darker-50: #{$g8-secondary-darker-50};

	--g8-secondary-opacity-10: #{$g8-secondary-opacity-10};
	--g8-secondary-opacity-25: #{$g8-secondary-opacity-25};
	--g8-secondary-opacity-50: #{$g8-secondary-opacity-50};
	--g8-secondary-opacity-75: #{$g8-secondary-opacity-75};
	--g8-secondary-opacity-90: #{$g8-secondary-opacity-90};

	--g8-bg-primary: #ffffff;
	--g8-bg-secondary: #f8f9fa;

	--bs-primary: var(--g8-primary);
	--bs-secondary: var(--g8-secondary);

	--bs-dropdown-link-active-bg: var(--g8-primary);
	/* --bs-primary-rgb: rgba(var(--g8-primary), 1) !important; */
	/* --bs-secondary-rgb: rgba(var(--g8-secondary), 1) !important; */
}

.text-primary {
	color: var(--g8-primary) !important;
}
.text-secondary {
	color: var(--g8-secondary) !important;
}
.text-bg-primary {
	background-color: var(--g8-primary) !important;
}
.text-bg-secondary {
	background-color: var(--g8-secondary) !important;
}

.bg-primary {
	background-color: var(--g8-bg-primary) !important;
}
.bg-secondary {
	background-color: var(--g8-bg-secondary) !important;
}
.dropdown-item.active,
.dropdown-item:active {
	background-color: var(--g8-primary);
}

.form-check-input:checked {
	background-color: var(--g8-primary);
	border-color: var(--g8-primary);
}

@mixin button-styles($color) {
	--bs-btn-bg: var(#{$color}) !important;
	--bs-btn-border-color: var(#{$color}) !important;
	--bs-btn-hover-bg: var(#{$color}-darker-15) !important;
	--bs-btn-hover-border-color: var(#{$color}-darker-20) !important;
	--bs-btn-active-bg: var(#{$color}-darker-50) !important;
	--bs-btn-active-border-color: var(#{$color}-darker-50) !important;
	--bs-btn-disabled-bg: var(#{$color}) !important;
	--bs-btn-disabled-border-color: var(#{$color}) !important;
}

.btn-primary {
	@include button-styles(--g8-primary);
}

.btn-secondary {
	@include button-styles(--g8-secondary);
}
